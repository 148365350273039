
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyATAYnVcbRSyhWhm5qxmuUeP1-Y5mzi4KU",
    authDomain: "my-farm-land-556ff.firebaseapp.com",
    projectId: "my-farm-land-556ff",
    storageBucket: "my-farm-land-556ff.appspot.com",
    messagingSenderId: "237838402938",
    appId: "1:237838402938:web:e130c81c1642b1867082c3",
    measurementId: "G-197F28V1FW"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
